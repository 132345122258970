import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { UAParser } from 'ua-parser-js';
import { detectIncognito } from 'detectincognitojs';

export function usePwaInstall() {
  const PWA_HIDDEN_KEY = 'PWA_HIDDEN';
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { openModal } = useModalStore();

  const isHidden = ref<boolean>(localStorage.getItem(PWA_HIDDEN_KEY) === 'true');

  const isPrivateMode = ref<boolean>(false);
  detectIncognito().then(({ isPrivate }) => {
    isPrivateMode.value = isPrivate;
  });
  const userAgent = window.navigator.userAgent;
  const parsedUserAgent = userAgent ? UAParser(userAgent) : undefined;
  const appleNotCompatible = parsedUserAgent?.browser?.name === 'Safari' || parsedUserAgent?.os?.name === 'iOS';
  const allowInstall = ref<boolean>(!!window.pwa?.allowInstall || appleNotCompatible);

  const isDuplicate = computed(() => {
    return profile.value?.segmentIds.includes(
      layoutData?.pwa?.duplicateSegmentUuid || defaultLocaleLayoutData?.pwa?.duplicateSegmentUuid || ''
    );
  });
  const isVisible = computed(() => {
    return !profile.value?.pwaInstalled && (allowInstall.value || isPrivateMode.value) && !isHidden.value;
  });

  onMounted(() => {
    if (window.pwa) {
      window.pwa.onInit(() => {
        allowInstall.value = !!window.pwa?.allowInstall;
      });
    }
  });

  const installPWA = async () => {
    if (isPrivateMode.value) {
      await openModal('pwa-not-available', {
        props: {
          platform: parsedUserAgent?.os?.name,
          browser: parsedUserAgent?.browser?.name,
          isDuplicate: isDuplicate.value,
        },
      });
      return;
    }

    if (appleNotCompatible) {
      await openModal('pwa-install-guide', {
        props: {
          platform: parsedUserAgent?.os?.name,
          browser: parsedUserAgent?.browser?.name,
          isDuplicate: isDuplicate.value,
        },
      });
    } else {
      window.pwa?.install().then(res => {
        if (res.outcome === 'accepted') {
          allowInstall.value = false;
        }
      });
    }
  };

  const showQrModal = () => {
    openModal('pwa-qr');
  };

  const hideBanner = () => {
    isHidden.value = true;
  };

  return {
    isVisible,
    isDuplicate,
    installPWA,
    showQrModal,
    hideBanner,
  };
}
